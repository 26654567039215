import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Tags from "../components/tags"
import {
  Column3,
  ContainerCol2,
  Sidebar,
  Title,
  Thumbnail,
  FlexWrap,
} from "../utils"

const Article = styled.article``
const TagsList = styled.ul`
  display: flex;
  li {
    margin-right: 1rem;
  }
`
const CreatedAt = styled.p``

const BlogPage = ({ data }) => {
  const blogPosts = data.blogPosts.edges
  return (
    <ContainerCol2>
      <Column3>
        {blogPosts &&
          blogPosts.map(({ node: post }) => {
            return (
              <Article>
                <Link to={`/blog/${post.slug}`}>
                  <Thumbnail>
                    <GatsbyImage image={getImage(post.thumbnail)}></GatsbyImage>
                  </Thumbnail>
                  <FlexWrap>
                    <p>Blog</p>
                    <CreatedAt>{post.createdAt}</CreatedAt>
                  </FlexWrap>
                  <Title>{post.title}</Title>
                  <TagsList>
                    {post.tags.map(tag => {
                      return <li key={tag.id}>#{tag.name}</li>
                    })}
                  </TagsList>
                </Link>
              </Article>
            )
          })}
      </Column3>
      <Sidebar>
        <Tags />
      </Sidebar>
    </ContainerCol2>
  )
}

export default BlogPage

export const query = graphql`
  query {
    blogPosts: allContentfulPosts(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          createdAt(formatString: "YYYY-MM-DD")
          slug
          tags {
            name
            id
          }
          title
          thumbnail {
            gatsbyImageData(width: 400)
          }
        }
      }
    }
  }
`
